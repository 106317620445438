import React from "react";
import { Card, CardOverrides } from "baseui/card";

export function ShadowedCard({
  children,
  overrides,
  ...rest
}: {
  children: React.ReactNode;
  overrides?: CardOverrides;
  [key: string]: any;
}) {
  return (
    <Card
      {...rest}
      overrides={{
        ...overrides,
        Root: {
          ...overrides?.Root,
          style: {
            ...overrides?.Root?.style,
            borderTopWidth: "0",
            borderLeftWidth: "0",
            borderRightWidth: "0",
            borderBottomWidth: "0",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
            boxShadow: "5px 5px 30px 1px rgba(0,0,0,.3)",
          },
        },
      }}
    >
      {children}
    </Card>
  );
}
