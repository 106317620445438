import { createContext, useContext, useMemo } from "react";
import { TrpcRouterOutput, trpc } from "../utils/trpc";
import LoadingPage from "../components/LoadingPage";
import ErrorPage from "../components/ErrorPage";
import { AVAILABLE_LANGUAGES, AvailableLanguage } from "../i18n/types";
import { isNotEmpty } from "../utils/array";

export type Section =
  TrpcRouterOutput["menu"]["public"]["getMenuData"]["sections"][number];
export type Item =
  TrpcRouterOutput["menu"]["public"]["getMenuData"]["items"][number];

type MenuContextValue = Omit<
  TrpcRouterOutput["menu"]["public"]["getMenuData"],
  "sections" | "items" | "availableLanguages"
> & {
  sections: Map<string, Section>;
  items: Map<string, Item>;
  availableLanguages: AvailableLanguage[];
};

const initialState: MenuContextValue = {
  name: "",
  slug: "",
  defaultLanguage: "",
  availableLanguages: [],
  allowOnlineOrdering: false,
  rootSectionUuid: "",
  sections: new Map(),
  items: new Map(),
};

const MenuContext = createContext<MenuContextValue>(initialState);

export function MenuContextProvider({
  slug,
  children,
}: {
  slug: string;
  children: React.ReactNode;
}) {
  const menuData = trpc.menu.public.getMenuData.useQuery({ slug });

  const sections = useMemo(() => {
    const sections = new Map<string, Section>();

    if (!menuData.data) {
      return sections;
    }

    menuData.data.sections.forEach((section) => {
      sections.set(section.uuid, section);
    });

    return sections;
  }, [menuData.data]);

  const items = useMemo(() => {
    const items = new Map<string, Item>();

    if (!menuData.data) {
      return items;
    }

    menuData.data.items.forEach((item) => {
      items.set(item.uuid, item);
    });

    return items;
  }, [menuData.data]);

  if (menuData.isLoading) {
    return <LoadingPage />;
  }

  if (menuData.error) {
    return <ErrorPage error={menuData.error.message} />;
  }

  const availableLanguages: AvailableLanguage[] =
    menuData.data.availableLanguages
      .map((language) => {
        const availableLanguage = AVAILABLE_LANGUAGES.find(
          (l) => l === language
        );

        if (!availableLanguage) {
          return null;
        }

        return availableLanguage;
      })
      .filter(isNotEmpty);

  return (
    <MenuContext.Provider
      value={{
        name: menuData.data.name,
        slug: menuData.data.slug,
        defaultLanguage: menuData.data.defaultLanguage,
        availableLanguages,
        allowOnlineOrdering: menuData.data.allowOnlineOrdering,
        rootSectionUuid: menuData.data.rootSectionUuid,
        sections,
        items,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}

export const useMenuContext = () => useContext(MenuContext);
