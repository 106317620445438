export const AVAILABLE_LANGUAGES = [
  "it-it",
  "en-gb",
  "fr-fr",
  "es-es",
  "de-de",
  "zh-cn",
] as const;

export type AvailableLanguage = (typeof AVAILABLE_LANGUAGES)[number];

// The type 'readonly ["it-it", ...]' is 'readonly' and cannot be assigned to the mutable type '("it-it" | ...)[]'.ts(2352)
// https://github.com/Microsoft/TypeScript/issues/13347
export const MUTABLE_AVAILABLE_LANGUAGES =
  AVAILABLE_LANGUAGES as unknown as AvailableLanguage[];

type Translation<LanguageCode = string> = {
  languageCode: LanguageCode;
  value: string;
};
export type StringTranslation = { translations: Translation[] };

export type MandatoryAllStringTranslations = {
  translations: [
    Translation<(typeof AVAILABLE_LANGUAGES)[0]>,
    Translation<(typeof AVAILABLE_LANGUAGES)[1]>,
    Translation<(typeof AVAILABLE_LANGUAGES)[2]>,
    Translation<(typeof AVAILABLE_LANGUAGES)[3]>,
    Translation<(typeof AVAILABLE_LANGUAGES)[4]>,
    Translation<(typeof AVAILABLE_LANGUAGES)[5]>
  ];
};
