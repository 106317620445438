import React from "react";

export default function SatispayIcon({
  style,
}: {
  style?: React.CSSProperties;
}) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 71.9 72"
      style={style}
    >
      <path
        style={{ fill: "#f94c43" }}
        d="M57.5 1.9c.4-.4.4-1.2 0-1.6-.2-.2-.5-.3-.8-.3H40l9.7 9.7 7.8-7.8zM34.2 25.3l-12-12-10.7 10.8L.3 35.2c-.4.4-.4 1.2 0 1.6L11.5 48l10.7 10.7 11.9-11.9-10-10-.7-.8.8-.8 10-9.9zm15.5 37L40 72h16.6c.6 0 1.1-.5 1.1-1.1 0-.3-.1-.6-.3-.8l-7.7-7.8z"
      />
      <path
        style={{ fill: "#f94c43" }}
        d="M71.6 35.2 60.5 24.1 47.9 11.5 36.7.3c-.2-.2-.4-.3-.7-.3H15.2c-.6 0-1.1.5-1.1 1.1 0 .3.1.6.3.8l9.6 9.6 12 12 11.8 11.8.8.8-.8.8L36 48.6 24 60.5l-9.6 9.6c-.4.4-.4 1.2 0 1.6.2.2.5.3.8.3H36c.3 0 .6-.1.8-.3l11.1-11.1L60.5 48l11.1-11.1c.4-.5.4-1.2 0-1.7"
      />
    </svg>
  );
}
