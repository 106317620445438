import { Navigate, useNavigate, useParams } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import {
  MenuContextProvider,
  useMenuContext,
} from "../../contexts/MenuContext";
import { MobileHeader } from "baseui/mobile-header";
import { Block } from "baseui/block";
import SectionPage from "./SectionPage";
import { I18nContextProvider } from "../../contexts/I18nContext";
import homeIcon from "../../images/home_icon.svg";

import CurrentLanguageIcon from "../i18n/CurrentLanguageIcon";
import { useEffect, useState } from "react";
import ChangeLanguageModal from "../i18n/ChangeLanguageModal";
import { OrderContextProvider } from "../../contexts/OrderContext";
import CartFloatingButton from "./order/CartFloatingButton";

export default function MenuContainer() {
  const { slug } = useParams();

  if (!slug) {
    return <ErrorPage error="Error" />;
  }

  if (slug.toUpperCase() !== slug) {
    return <Navigate to={`/${slug.toUpperCase()}`} replace />;
  }

  return (
    <MenuContextProvider slug={slug}>
      <OrderContextProvider slug={slug}>
        <MenuWithI18n />
      </OrderContextProvider>
    </MenuContextProvider>
  );
}

function MenuWithI18n() {
  const { availableLanguages } = useMenuContext();

  return (
    <I18nContextProvider availableLanguages={availableLanguages}>
      <Menu />
    </I18nContextProvider>
  );
}

function Menu() {
  const menuData = useMenuContext();
  const navigate = useNavigate();

  const [showChangeLanguage, setShowChangeLanguage] = useState(false);

  useEffect(() => {
    document.title = menuData.name;
  }, [menuData.name]);

  return (
    <>
      <MobileHeader
        title={menuData.name}
        navButton={{
          renderIcon: () => <img src={homeIcon} alt="Home" />,
          onClick: () => navigate(`/${menuData.slug}`),
          label: "Home",
        }}
        actionButtons={[
          {
            renderIcon: () => <CurrentLanguageIcon />,
            onClick: () => setShowChangeLanguage(true),
            label: "Change language",
          },
        ]}
      />
      <ChangeLanguageModal
        isOpen={showChangeLanguage}
        onClose={() => setShowChangeLanguage(false)}
      />
      <Block
        overrides={{
          Block: {
            style: {
              maxWidth: "900px",
              margin: "auto",
              padding: "1rem",
            },
          },
        }}
      >
        <SectionPage sectionUuid={menuData.rootSectionUuid} />
        <Footer />
      </Block>
      <CartFloatingButton />
    </>
  );
}

const footerLinkStyle = {
  color: "inherit",
  textDecoration: "none",
  opacity: 0.5,
  fontSize: "0.8em",
};

function Footer() {
  return (
    <Block
      overrides={{
        Block: {
          style: { textAlign: "left", marginTop: "4em" },
        },
      }}
    >
      <a
        href="https://www.simpulse.it/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
        style={footerLinkStyle}
      >
        Privacy Policy
      </a>
      <br />
      <a
        href="https://www.simpulse.it/"
        target="_blank"
        rel="noopener noreferrer"
        style={footerLinkStyle}
      >
        Powered by SimPulse.it
      </a>
    </Block>
  );
}
