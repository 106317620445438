import { useI18nContext } from "../../contexts/I18nContext";

export default function CurrentLanguageIcon() {
  const { currentLanguage } = useI18nContext();

  return (
    <img
      src={currentLanguage.imageUrl}
      alt={currentLanguage.name}
      style={{ borderRadius: "50%", width: "50%" }}
    />
  );
}
