import { createContext, useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { isNotEmpty } from "../utils/array";
import ErrorPage from "../components/ErrorPage";
import { AvailableLanguage } from "../i18n/types";

type Language = {
  code: AvailableLanguage;
  name: string;
  imageUrl: string;
};

const languages: Language[] = [
  {
    code: "en-gb",
    name: "English",
    imageUrl:
      "https://raw.githubusercontent.com/lipis/flag-icons/main/flags/1x1/gb.svg",
  },
  {
    code: "it-it",
    name: "Italiano",
    imageUrl:
      "https://raw.githubusercontent.com/lipis/flag-icons/main/flags/1x1/it.svg",
  },
  {
    code: "fr-fr",
    name: "Français",
    imageUrl:
      "https://raw.githubusercontent.com/lipis/flag-icons/main/flags/1x1/fr.svg",
  },
];

type I18nContextValue = {
  availableLanguages: Language[];
  currentLanguage: Language;
  setCurrentLanguage: (language: Language) => void;
};

const initialState: I18nContextValue = {
  availableLanguages: languages,
  currentLanguage: languages[0],
  setCurrentLanguage: () => {},
};

const I18nContext = createContext<I18nContextValue>(initialState);

export function I18nContextProvider({
  availableLanguages: availableLanguageCodes,
  children,
}: {
  availableLanguages: Array<AvailableLanguage>;
  children: React.ReactNode;
}) {
  const [cookies, setCookie] = useCookies(["language"]);

  const availableLanguages = availableLanguageCodes
    .map((languageCode) => languages.find((l) => l.code === languageCode))
    .filter(isNotEmpty);

  const initialLanguage = availableLanguages.find(
    (language) => language.code === cookies.language
  );

  const [currentLanguage, setCurrentLanguage] = useState<Language>(
    initialLanguage || availableLanguages[0]
  );

  if (availableLanguages.length === 0) {
    return <ErrorPage error="No languages available" />;
  }

  return (
    <I18nContext.Provider
      value={{
        availableLanguages: availableLanguages,
        currentLanguage,
        setCurrentLanguage: (language) => {
          setCookie("language", language.code);
          setCurrentLanguage(language);
        },
      }}
    >
      {children}
    </I18nContext.Provider>
  );
}

export const useI18nContext = () => useContext(I18nContext);
