import notFoundImage from "../images/not_found_image.svg";
import { Block } from "baseui/block";

export default function ErrorPage({ error }: { error: string }) {
  return (
    <Block
      overrides={{
        Block: {
          style: {
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          },
        },
      }}
    >
      <h1>{error}</h1>
      <img
        src={notFoundImage}
        alt="404 not found"
        style={{ maxWidth: "100%" }}
      />
    </Block>
  );
}
