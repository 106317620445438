import {
  Button,
  SIZE as BUTTON_SIZE,
  SHAPE as BUTTON_SHAPE,
  KIND as BUTTON_KIND,
} from "baseui/button";
import { ModalBody, ModalButton, ModalFooter } from "baseui/modal";
import { HeadingMedium } from "baseui/typography";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useMemo } from "react";
import { MdShoppingCart, MdDeleteForever } from "react-icons/md";
import { useOrderContext } from "../../../contexts/OrderContext";
import TranslatedString from "../../i18n/TranslatedString";
import { type Item, useMenuContext } from "../../../contexts/MenuContext";
import PriceQuantitySelector from "./PriceQuantitySelector";
import { StyledDivider, SIZE as DIVIDER_SIZE } from "baseui/divider";
import { Notification } from "baseui/notification";
import { formatCurrency } from "../../../utils/currency";
import { Block } from "baseui/block";
import { Link, useNavigate } from "react-router-dom";
import ModalHashRoute from "../../common/ModalHashRoute";
import { trpc } from "../../../utils/trpc";
import { useI18nContext } from "../../../contexts/I18nContext";
import { isNotEmpty } from "../../../utils/array";
import I18N_STRINGS from "../../../i18n/strings";
import { ShadowedCard } from "@simpulse/web-public-common";

export default function CartFloatingButton() {
  const { currentOrder } = useOrderContext();

  return (
    <>
      {currentOrder.itemPrices.length > 0 && (
        <Link to="#order" state={{ from: "page" }}>
          <Button
            size={BUTTON_SIZE.large}
            shape={BUTTON_SHAPE.pill}
            overrides={{
              BaseButton: {
                style: {
                  position: "fixed",
                  bottom: "1em",
                  right: "1em",
                },
              },
            }}
          >
            <MdShoppingCart style={{ marginInlineEnd: ".5em" }} />
            <TranslatedString string={I18N_STRINGS.YOUR_ORDER} />
          </Button>
        </Link>
      )}
      <OrderModal />
    </>
  );
}

function OrderModal() {
  const [animationParent] = useAutoAnimate();
  const { items } = useMenuContext();
  const { currentOrder } = useOrderContext();

  const itemPriceToItemMap = useMemo(() => {
    const result = new Map<string, string>();

    items.forEach((item) => {
      item.prices.forEach((price) => {
        result.set(price.uuid, item.uuid);
      });
    });

    return result;
  }, [items]);

  const itemsWithPricesInOrder = useMemo(() => {
    const result: Array<{
      item: Item;
      prices: Array<{
        price: Item["prices"][0];
        quantity: number;
        note: string;
      }>;
    }> = [];

    currentOrder.itemPrices.forEach((itemPrice) => {
      const itemUuid = itemPriceToItemMap.get(itemPrice.itemPriceUuid);
      if (!itemUuid) {
        return;
      }

      const item = items.get(itemUuid);
      if (!item) {
        return;
      }

      const price = item.prices.find(
        (price) => price.uuid === itemPrice.itemPriceUuid
      );
      if (!price) {
        return;
      }

      const existingItem = result.find((item) => item.item.uuid === itemUuid);
      if (existingItem) {
        existingItem.prices.push({
          price,
          quantity: itemPrice.quantity,
          note: itemPrice.note,
        });
      } else {
        result.push({
          item,
          prices: [
            {
              price,
              quantity: itemPrice.quantity,
              note: itemPrice.note,
            },
          ],
        });
      }
    });

    return result;
  }, [currentOrder, itemPriceToItemMap, items]);

  return (
    <ModalHashRoute
      hash="#order"
      overrides={{
        Dialog: {
          style: {
            height: "calc(100dvh - 2em)",
            display: "flex",
            flexDirection: "column",
          },
        },
      }}
    >
      {({ onClose }) => (
        <>
          <ModalBody
            $style={{
              overflowY: "auto",
              flexGrow: 1,
              marginTop: "0",
              marginBottom: "0",
              marginLeft: "0",
              marginRight: "0",
              padding: "0 2em 2em 2em",
              borderRadius: "16px",
              boxShadow: "0 10px 30px -10px rgba(0,0,0,.5)",
            }}
          >
            <HeadingMedium>Order</HeadingMedium>
            {itemsWithPricesInOrder.length === 0 && (
              <Notification
                overrides={{
                  Body: { style: { width: "auto" } },
                }}
              >
                {() => <TranslatedString string={I18N_STRINGS.EMPTY_ORDER} />}
              </Notification>
            )}
            <div ref={animationParent}>
              {itemsWithPricesInOrder.map((item) => (
                <ItemWithPrice
                  item={item}
                  key={`order-item-${item.item.uuid}`}
                />
              ))}
              {itemsWithPricesInOrder.length > 0 && (
                <>
                  <ShadowedCard>
                    <div
                      style={{
                        fontWeight: "bold",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        <TranslatedString string={I18N_STRINGS.ORDER_TOTAL} />
                      </span>
                      <span>
                        {formatCurrency(
                          itemsWithPricesInOrder.reduce(
                            (total, item) =>
                              total +
                              item.prices.reduce(
                                (total, price) =>
                                  total + price.price.price * price.quantity,
                                0
                              ),
                            0
                          )
                        )}
                      </span>
                    </div>
                  </ShadowedCard>
                  <ClearOrderButton />
                </>
              )}
            </div>
          </ModalBody>
          <ModalFooter
            style={{
              paddingTop: "0.5em",
              paddingBottom: "0.5em",
              marginTop: "0",
            }}
          >
            <ModalButton kind={BUTTON_KIND.tertiary} onClick={onClose}>
              <TranslatedString string={I18N_STRINGS.CLOSE} />
            </ModalButton>
            <PlaceOrderButton />
          </ModalFooter>
        </>
      )}
    </ModalHashRoute>
  );
}

function ItemWithPrice({
  item,
}: {
  item: {
    item: Item;
    prices: Array<{
      price: Item["prices"][0];
      quantity: number;
      note: string;
    }>;
  };
}) {
  const [animationParent] = useAutoAnimate();

  return (
    <Block overrides={{ Block: { style: { marginBottom: "1rem" } } }}>
      <ShadowedCard title={<TranslatedString string={item.item.name} />}>
        <div ref={animationParent}>
          {item.prices.map((price, i) => (
            <div key={`order-price-${price.price.uuid}`}>
              <TranslatedString string={price.price.name} />
              <div
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  {price.quantity} × {formatCurrency(price.price.price)}
                </span>
                <span>
                  {formatCurrency(price.price.price * price.quantity)}
                </span>
              </div>
              <PriceQuantitySelector price={price.price} secondary />
              {i < item.prices.length - 1 && (
                <StyledDivider $size={DIVIDER_SIZE.cell} />
              )}
            </div>
          ))}
        </div>
      </ShadowedCard>
    </Block>
  );
}

function ClearOrderButton() {
  const { clearOrder } = useOrderContext();

  return (
    <Button
      size={BUTTON_SIZE.compact}
      shape={BUTTON_SHAPE.pill}
      kind={BUTTON_KIND.secondary}
      onClick={() => {
        if (
          window.confirm(
            "Are you sure you want to clear your order? Everything will be deleted."
          )
        ) {
          clearOrder();
        }
      }}
      overrides={{
        BaseButton: {
          style: {
            marginTop: "1em",
          },
        },
      }}
    >
      <MdDeleteForever /> <TranslatedString string={I18N_STRINGS.CLEAR_ORDER} />
    </Button>
  );
}

function PlaceOrderButton() {
  const navigate = useNavigate();
  const { allowOnlineOrdering, slug, items } = useMenuContext();
  const { currentLanguage } = useI18nContext();
  const { currentOrder } = useOrderContext();
  const placeOrder = trpc.menu.public.createOrderDraft.useMutation();

  if (!allowOnlineOrdering) {
    return null;
  }

  const onClick = async () => {
    try {
      const itemPriceToItemMap = new Map<string, string>();
      items.forEach((item) => {
        item.prices.forEach((price) => {
          itemPriceToItemMap.set(price.uuid, item.uuid);
        });
      });

      const itemsForOrder: {
        itemUuid: string;
        itemPriceUuid: string;
        quantity: number;
      }[] = currentOrder.itemPrices
        .map((itemPrice) => {
          const itemUuid = itemPriceToItemMap.get(itemPrice.itemPriceUuid);
          if (!itemUuid) {
            return null;
          }
          return {
            itemUuid,
            itemPriceUuid: itemPrice.itemPriceUuid,
            quantity: itemPrice.quantity,
          };
        })
        .filter(isNotEmpty);

      const totalPrice = itemsForOrder.reduce(
        (total, item) =>
          total +
          (items
            .get(item.itemUuid)
            ?.prices.find((price) => price.uuid === item.itemPriceUuid)
            ?.price || 0) *
            item.quantity,
        0
      );

      const { uuid, token } = await placeOrder.mutateAsync({
        menuSlug: slug,
        userLanguage: currentLanguage.code,
        items: itemsForOrder,
        totalPrice,
      });

      navigate(`/order/${uuid}/${token}`);
    } catch (e) {
      alert("Error placing order");
      console.error(e);
    }
  };

  const isOrderEmtpy = currentOrder.itemPrices.length === 0;

  return (
    <Button
      onClick={onClick}
      isLoading={placeOrder.isLoading}
      disabled={placeOrder.isLoading || isOrderEmtpy}
    >
      <TranslatedString string={I18N_STRINGS.PLACE_ORDER} />
    </Button>
  );
}
