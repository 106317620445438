import { Route, Routes, useParams } from "react-router-dom";
import { useMenuContext } from "../../contexts/MenuContext";
import Item from "./elements/Item";
import Section from "./elements/Section";
import { Block } from "baseui/block";
import React from "react";
import TranslatedString from "../i18n/TranslatedString";

export default function SectionPage({ sectionUuid }: { sectionUuid: string }) {
  const menuData = useMenuContext();

  const section = menuData.sections.get(sectionUuid);

  if (!section) {
    return null;
  }

  return (
    <Routes>
      <Route path="/:sectionUuid/*" element={<SectionPageFromParams />} />
      <Route
        path="/*"
        element={
          <div>
            <h1>
              <TranslatedString string={section.name} />
            </h1>
            {section.elementsUuids.map((e) => (
              <Element
                key={
                  e.type === "item"
                    ? menuData.items.get(e.uuid)?.uuid
                    : menuData.sections.get(e.uuid)?.uuid
                }
                type={e.type}
                uuid={e.uuid}
              />
            ))}
          </div>
        }
      />
    </Routes>
  );
}

function SectionPageFromParams() {
  const { sectionUuid } = useParams();

  if (!sectionUuid) {
    return null;
  }

  return <SectionPage sectionUuid={sectionUuid} />;
}

function Element({ type, uuid }: { type: "item" | "section"; uuid: string }) {
  const menuData = useMenuContext();

  let returnElement: React.ReactNode;
  if (type === "item") {
    const item = menuData.items.get(uuid);

    if (!item) {
      return null;
    }

    returnElement = <Item item={item} />;
  } else {
    const section = menuData.sections.get(uuid);

    if (!section) {
      return null;
    }

    returnElement = <Section section={section} />;
  }

  return (
    <Block
      overrides={{
        Block: {
          style: {
            marginBottom: "1rem",
          },
        },
      }}
    >
      {returnElement}
    </Block>
  );
}
