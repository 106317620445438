import React from "react";

export default function CardIcon({ style }: { style?: React.CSSProperties }) {
  return (
    <svg
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      style={style}
    >
      <path
        clip-rule="evenodd"
        d="M43 40H5a4 4 0 0 1-4-4V12a4 4 0 0 1 4-4h38a4 4 0 0 1 4 4v24a4 4 0 0 1-4 4zM3 21h42v-4H3v4zm42-9a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v3h42v-3zm0 11H3v13a2 2 0 0 0 2 2h38a2 2 0 0 0 2-2V23zm-19 6h-4a1 1 0 1 1 0-2h4a1 1 0 1 1 0 2zm-9 0H8a1 1 0 1 1 0-2h9a1 1 0 1 1 0 2zm-9 3h6a1 1 0 1 1 0 2H8a1 1 0 1 1 0-2z"
        fill-rule="evenodd"
      />
    </svg>
  );
}
