import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "baseui/modal";
import { Button, KIND as BUTTON_KIND } from "baseui/button";
import { useI18nContext } from "../../contexts/I18nContext";
import { Block } from "baseui/block";

export default function ChangeLanguageModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { availableLanguages, currentLanguage, setCurrentLanguage } =
    useI18nContext();

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalHeader>Change language</ModalHeader>
      <ModalBody>
        <Block
          overrides={{
            Block: {
              style: {
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "1em",
              },
            },
          }}
        >
          {availableLanguages.map((language) => (
            <Button
              key={language.code}
              onClick={() => {
                setCurrentLanguage(language);
                onClose();
              }}
              overrides={{ BaseButton: { style: { width: "100%" } } }}
              kind={
                language.code === currentLanguage.code
                  ? BUTTON_KIND.primary
                  : BUTTON_KIND.secondary
              }
              startEnhancer={() => (
                <img
                  src={language.imageUrl}
                  alt={language.name}
                  style={{
                    borderRadius: "50%",
                    width: "1.3em",
                  }}
                />
              )}
            >
              {language.name}
            </Button>
          ))}
        </Block>
      </ModalBody>
      <ModalFooter>
        <ModalButton kind="tertiary" onClick={onClose}>
          Close
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}
