import { Link, useParams } from "react-router-dom";
import ErrorPage from "../ErrorPage";
import { TrpcRouterOutput, trpc } from "../../utils/trpc";
import LoadingPage from "../LoadingPage";
import { MobileHeader } from "baseui/mobile-header";
import { Block } from "baseui/block";
import { KIND as NOTIFICATION_KIND } from "baseui/notification";
import { PhoneInput } from "baseui/phone-input";
import type { Country } from "baseui/phone-input";
import { formatCurrency } from "../../utils/currency";
import {
  Button,
  SIZE as BUTTON_SIZE,
  KIND as BUTTON_KIND,
  SHAPE as BUTTON_SHAPE,
} from "baseui/button";
import { useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { MdAdd, MdExpandLess, MdExpandMore } from "react-icons/md";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Textarea } from "baseui/textarea";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import ShadowedNotification from "../common/ShadowedNotification";
import TranslatedString from "../i18n/TranslatedString";
import I18N_STRINGS from "../../i18n/strings";
import { MUTABLE_AVAILABLE_LANGUAGES } from "../../i18n/types";
import {
  I18nContextProvider,
  useI18nContext,
} from "../../contexts/I18nContext";
import CurrentLanguageIcon from "../i18n/CurrentLanguageIcon";
import ChangeLanguageModal from "../i18n/ChangeLanguageModal";
import {
  PaymentMethodSelector,
  ShadowedCard,
} from "@simpulse/web-public-common";

type Order = TrpcRouterOutput["menu"]["public"]["getOrder"];

export default function OrderContainer() {
  const { id, token } = useParams();

  if (!id || !token) {
    return <ErrorPage error="Error" />;
  }

  const orderQuery = trpc.menu.public.getOrder.useQuery({ uuid: id, token });

  if (orderQuery.isLoading) {
    return <LoadingPage />;
  }

  if (orderQuery.error) {
    return <ErrorPage error={orderQuery.error.message} />;
  }

  const order = orderQuery.data;

  return <OrderPage order={order} token={token} />;
}

function OrderPage({ order, token }: { order: Order; token: string }) {
  const [showChangeLanguage, setShowChangeLanguage] = useState(false);

  return (
    <I18nContextProvider availableLanguages={MUTABLE_AVAILABLE_LANGUAGES}>
      <MobileHeader
        title="Order"
        actionButtons={[
          {
            renderIcon: () => <CurrentLanguageIcon />,
            onClick: () => setShowChangeLanguage(true),
            label: "Change language",
          },
        ]}
      />
      <ChangeLanguageModal
        isOpen={showChangeLanguage}
        onClose={() => setShowChangeLanguage(false)}
      />
      <Block
        overrides={{
          Block: {
            style: {
              maxWidth: "900px",
              margin: "0 auto 2em auto",
              padding: "1rem 1rem 2rem 1rem",
            },
          },
        }}
      >
        <OrderDetails order={order} />
        <OrderForm order={order} token={token} />
      </Block>
    </I18nContextProvider>
  );
}

function OrderDetails({ order }: { order: Order }) {
  const [parentRef] = useAutoAnimate();
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <OrderStatus order={order} />
      {order.status !== "draft" && (
        <Block
          overrides={{
            Block: { style: { marginTop: "1rem", marginBottom: "1rem" } },
          }}
        >
          <ShadowedCard>
            <>
              <b>
                {order.firstName} {order.lastName}
              </b>
              <br />
              {order.email} • {order.phone}
              {order.notes && (
                <>
                  <p>
                    <b>Notes:</b>
                    <br />
                    {order.notes}
                  </p>
                </>
              )}
            </>
          </ShadowedCard>
        </Block>
      )}
      <ShadowedCard>
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              size={BUTTON_SIZE.compact}
              kind={BUTTON_KIND.secondary}
              shape={BUTTON_SHAPE.pill}
              onClick={() => setShowDetails(!showDetails)}
              startEnhancer={() => (
                <>{showDetails ? <MdExpandLess /> : <MdExpandMore />}</>
              )}
            >
              <TranslatedString
                string={
                  showDetails
                    ? I18N_STRINGS.HIDE_ORDER_DETAILS
                    : I18N_STRINGS.SHOW_ORDER_DETAILS
                }
              />
            </Button>
            <b>
              <TranslatedString string={I18N_STRINGS.ORDER_TOTAL} />:{" "}
              {formatCurrency(order.totalPrice)}
            </b>
          </div>
          <div ref={parentRef}>
            {showDetails && (
              <>
                {order.items.map((item) => (
                  <Block
                    key={item.uuid}
                    overrides={{ Block: { style: { marginTop: "1rem" } } }}
                  >
                    <ShadowedCard>
                      <b>{item.itemName}</b>
                      {item.itemPriceName && <> ({item.itemPriceName})</>}
                      <div
                        style={{
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          {item.quantity} × {formatCurrency(item.unitPrice)}
                        </span>
                        <span>{formatCurrency(item.totalPrice)}</span>
                      </div>
                    </ShadowedCard>
                  </Block>
                ))}
              </>
            )}
          </div>
        </>
      </ShadowedCard>
    </>
  );
}

const notificationOverrides = {
  Body: {
    style: {
      width: "auto",
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
    },
  },
  InnerContainer: {
    style: {
      width: "100%",
    },
  },
};

function OrderStatus({ order }: { order: Order }) {
  if (order.status === "draft") {
    return (
      <ShadowedNotification
        kind={NOTIFICATION_KIND.warning}
        overrides={notificationOverrides}
      >
        <TranslatedString string={I18N_STRINGS.ORDER_NOT_CONFIRMED_FILL_FORM} />
      </ShadowedNotification>
    );
  }

  if (order.status === "await_payment") {
    return (
      <ShadowedNotification
        kind={NOTIFICATION_KIND.warning}
        overrides={notificationOverrides}
      >
        <TranslatedString string={I18N_STRINGS.ORDER_NOT_CONFIRMED_PAY} />
        {order.paymentUrl && (
          <div style={{ width: "100%", marginTop: "1em", textAlign: "center" }}>
            <Link to={order.paymentUrl}>
              <Button type="button" kind={BUTTON_KIND.primary}>
                <TranslatedString string={I18N_STRINGS.PAY_NOW} />
              </Button>
            </Link>
          </div>
        )}
      </ShadowedNotification>
    );
  }

  return (
    <ShadowedNotification
      kind={NOTIFICATION_KIND.positive}
      overrides={notificationOverrides}
    >
      <TranslatedString string={I18N_STRINGS.ORDER_CONFIRMED} />
    </ShadowedNotification>
  );
}

function OrderForm({ order, token }: { order: Order; token: string }) {
  const { currentLanguage } = useI18nContext();
  const [parentRef] = useAutoAnimate();
  const [showNote, setShowNote] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [phoneCountry, setPhoneCountry] = useState<Country>({
    label: "Italy (Italia)",
    id: "IT",
    dialCode: "+39",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [paymentMethodUuid, setPaymentMethodUuid] = useState<string | null>(
    order.paymentMethods[0]?.uuid ?? null
  );

  const trpcContext = trpc.useUtils();
  const submitOrder = trpc.menu.public.submitOrder.useMutation();

  if (order.status !== "draft") {
    return null;
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formData = new FormData(e.currentTarget);

      const firstName = formData.get("firstName") as string;
      const lastName = formData.get("lastName") as string;
      const email = formData.get("email") as string;
      const phone = phoneCountry.dialCode + phoneNumber;
      const notes = (formData.get("notes") || "") as string;

      const result = await submitOrder.mutateAsync({
        uuid: order.uuid,
        token,
        data: {
          firstName,
          lastName,
          email,
          phone,
          notes,
          paymentMethodUuid,
        },
      });

      if (result.status === "submitted") {
        await trpcContext.menu.public.getOrder.refetch();
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setIsLoading(false);
      } else {
        window.location.replace(result.redirectUrl);
      }
    } catch (e) {
      alert("Error submitting order. Please try again.");
      console.error(e);
      setIsLoading(false);
    }
  };

  return (
    <ShadowedCard style={{ marginTop: "1rem" }}>
      <form onSubmit={onSubmit}>
        <FlexGrid flexGridColumnCount={[1, 1, 2]} flexGridColumnGap="scale800">
          <FlexGridItem>
            <FormControl
              label={<TranslatedString string={I18N_STRINGS.FIRST_NAME} />}
            >
              <Input
                id="first-name"
                name="firstName"
                required
                disabled={isLoading}
              />
            </FormControl>
          </FlexGridItem>
          <FlexGridItem>
            <FormControl
              label={<TranslatedString string={I18N_STRINGS.LAST_NAME} />}
            >
              <Input
                id="last-name"
                name="lastName"
                required
                disabled={isLoading}
              />
            </FormControl>
          </FlexGridItem>
          <FlexGridItem>
            <FormControl
              label={<TranslatedString string={I18N_STRINGS.EMAIL} />}
            >
              <Input
                id="email"
                name="email"
                type="email"
                required
                disabled={isLoading}
              />
            </FormControl>
          </FlexGridItem>
          <FlexGridItem>
            <FormControl
              label={<TranslatedString string={I18N_STRINGS.PHONE_NUMBER} />}
            >
              <PhoneInput
                id="phone"
                name="phone"
                country={phoneCountry}
                onCountryChange={({ option }) => setPhoneCountry(option as any)}
                text={phoneNumber}
                onTextChange={(e) => setPhoneNumber(e.currentTarget.value)}
                required
                disabled={isLoading}
              />
            </FormControl>
          </FlexGridItem>
        </FlexGrid>
        <div ref={parentRef}>
          {!showNote ? (
            <Button
              size={BUTTON_SIZE.compact}
              kind={BUTTON_KIND.secondary}
              shape={BUTTON_SHAPE.pill}
              type="button"
              onClick={() => setShowNote(true)}
              startEnhancer={() => <MdAdd />}
              overrides={{ BaseButton: { style: { marginBottom: "1rem" } } }}
              disabled={isLoading}
            >
              <TranslatedString string={I18N_STRINGS.ADD_NOTE} />
            </Button>
          ) : (
            <FormControl
              label={<TranslatedString string={I18N_STRINGS.NOTES} />}
            >
              <Textarea id="notes" name="notes" disabled={isLoading} />
            </FormControl>
          )}
        </div>
        {paymentMethodUuid && (
          <FormControl
            label={<TranslatedString string={I18N_STRINGS.PAYMENT_METHOD} />}
          >
            <PaymentMethodSelector
              paymentMethods={order.paymentMethods}
              paymentMethodUuid={paymentMethodUuid}
              setPaymentMethodUuid={setPaymentMethodUuid}
              language={currentLanguage.code}
            />
          </FormControl>
        )}
        <div style={{ textAlign: "center" }}>
          <Button
            type="submit"
            kind={BUTTON_KIND.primary}
            isLoading={isLoading}
            disabled={isLoading}
          >
            <TranslatedString string={I18N_STRINGS.CONFIRM_ORDER} />
          </Button>
        </div>
      </form>
    </ShadowedCard>
  );
}
