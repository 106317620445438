import { Section as SectionType } from "../../../contexts/MenuContext";
import { MessageCard } from "baseui/message-card";
import { useNavigate } from "react-router-dom";
import TranslatedString from "../../i18n/TranslatedString";

export default function Section({ section }: { section: SectionType }) {
  const navigate = useNavigate();

  return (
    <MessageCard
      heading={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>
            <TranslatedString string={section.name} />
          </span>
          <span>»</span>
        </div>
      }
      paragraph={<TranslatedString string={section.description} />}
      onClick={() => navigate(`./${section.uuid}`)}
      overrides={{
        Root: {
          style: {
            borderWidth: "0",
            borderRadius: "12px",
            boxShadow: "5px 5px 30px 1px rgba(0,0,0,.3)",
          },
        },
      }}
    />
  );
}
