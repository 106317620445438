import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { z } from "zod";

const orderValidator = z.object({
  itemPrices: z.array(
    z.object({
      itemPriceUuid: z.string().uuid(),
      quantity: z.number().min(1),
      note: z.string(),
    })
  ),
});

type Order = z.infer<typeof orderValidator>;

type OrderContextValue = {
  currentOrder: Order;
  addToOrder: (itemPriceUuid: string, quantity: number, note: string) => void;
  removeFromOrder: (itemPriceUuid: string, quantity: number) => void;
  setQuantity: (itemPriceUuid: string, quantity: number) => void;
  clearOrder: () => void;
  getQuantity: (itemPriceUuid: string) => number;
};

const initialState: OrderContextValue = {
  currentOrder: { itemPrices: [] },
  addToOrder: () => {},
  removeFromOrder: () => {},
  setQuantity: () => {},
  clearOrder: () => {},
  getQuantity: () => 0,
};

const OrderContext = createContext<OrderContextValue>(initialState);

export function OrderContextProvider({
  slug,
  children,
}: {
  slug: string;
  children: React.ReactNode;
}) {
  const [cookies, setCookie] = useCookies([`order-${slug}`]);

  const orderCookie = orderValidator.safeParse(cookies[`order-${slug}`]);
  const [currentOrder, setCurrentOrder] = useState<Order>(
    orderCookie.success ? orderCookie.data : { itemPrices: [] }
  );

  const addToOrder = (
    itemPriceUuid: string,
    quantity: number,
    note: string
  ) => {
    const newOrder = { ...currentOrder };

    const existingItemPrice = newOrder.itemPrices.find(
      (itemPrice) => itemPrice.itemPriceUuid === itemPriceUuid
    );

    if (existingItemPrice) {
      existingItemPrice.quantity += quantity;
      existingItemPrice.note = note;
    } else {
      newOrder.itemPrices.push({ itemPriceUuid, quantity, note });
    }

    setCurrentOrder(newOrder);
  };

  const removeFromOrder = (itemPriceUuid: string, quantity: number) => {
    const newOrder = { ...currentOrder };

    const existingItemPrice = newOrder.itemPrices.find(
      (itemPrice) => itemPrice.itemPriceUuid === itemPriceUuid
    );

    if (existingItemPrice) {
      existingItemPrice.quantity -= quantity;
      if (existingItemPrice.quantity <= 0) {
        newOrder.itemPrices = newOrder.itemPrices.filter(
          (itemPrice) => itemPrice.itemPriceUuid !== itemPriceUuid
        );
      }
    }

    setCurrentOrder(newOrder);
  };

  const clearOrder = () => {
    setCurrentOrder({ itemPrices: [] });
  };

  useEffect(() => {
    setCookie(`order-${slug}`, currentOrder, {
      path: "/",
      maxAge: 60 * 60 * 24 * 7, // 1 week
    });
  }, [setCookie, slug, currentOrder]);

  const setQuantity = useCallback(
    (itemPriceUuid: string, quantity: number) => {
      const newOrder = { ...currentOrder };

      const existingItemPrice = newOrder.itemPrices.find(
        (itemPrice) => itemPrice.itemPriceUuid === itemPriceUuid
      );

      if (existingItemPrice) {
        existingItemPrice.quantity = quantity;
      }

      setCurrentOrder(newOrder);
    },
    [currentOrder]
  );

  const getQuantity = useCallback(
    (itemPriceUuid: string) => {
      const itemPrice = currentOrder.itemPrices.find(
        (itemPrice) => itemPrice.itemPriceUuid === itemPriceUuid
      );
      return itemPrice ? itemPrice.quantity : 0;
    },
    [currentOrder]
  );

  return (
    <OrderContext.Provider
      value={{
        currentOrder,
        addToOrder,
        removeFromOrder,
        setQuantity,
        clearOrder,
        getQuantity,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
}

export const useOrderContext = () => useContext(OrderContext);
