import { useI18nContext } from "../../contexts/I18nContext";
import { type StringTranslation } from "../../i18n/types";

export default function TranslatedString({
  string,
}: {
  string: StringTranslation;
}) {
  const { currentLanguage } = useI18nContext();

  const translation = string.translations.find(
    (translation) => translation.languageCode === currentLanguage.code
  );

  if (!translation) {
    return null;
  }

  return <>{translation.value}</>;
}
