import { Item as ItemType } from "../../../contexts/MenuContext";
import { StyledBody, StyledThumbnail } from "baseui/card";
import { ListItem, ListItemLabel } from "baseui/list";
import TranslatedString from "../../i18n/TranslatedString";
import React from "react";
import { useOrderContext } from "../../../contexts/OrderContext";
import {
  Button,
  SIZE as BUTTON_SIZE,
  SHAPE as BUTTON_SHAPE,
  KIND as BUTTON_KIND,
} from "baseui/button";
import { Badge, COLOR as BADGE_COLOR } from "baseui/badge";
import { MdAdd } from "react-icons/md";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal";
import PriceQuantitySelector from "../order/PriceQuantitySelector";
import { formatCurrency } from "../../../utils/currency";
import I18N_STRINGS from "../../../i18n/strings";
import { ShadowedCard } from "@simpulse/web-public-common";

export default function Item({ item }: { item: ItemType }) {
  return (
    <ShadowedCard title={<TranslatedString string={item.name} />}>
      {item.imageUrl && (
        <StyledThumbnail
          src={item.imageUrl}
          style={{
            borderTopWidth: "0",
            borderLeftWidth: "0",
            borderRightWidth: "0",
            borderBottomWidth: "0",
            borderRadius: "12px",
            boxShadow: "5px 5px 10px 1px rgba(0,0,0,.3)",
          }}
        />
      )}
      <StyledBody>
        <TranslatedString string={item.description} />
        <Prices item={item} prices={item.prices} />
      </StyledBody>
    </ShadowedCard>
  );
}

function Prices({
  item,
  prices,
}: {
  item: ItemType;
  prices: ItemType["prices"];
}) {
  const displayedPrices = prices.filter((price) => price.isDisplayed);

  if (displayedPrices.length === 0) {
    return null;
  }

  if (displayedPrices.length === 1) {
    const price = displayedPrices[0];
    return (
      <>
        <br />
        <Price item={item} price={price} />
      </>
    );
  }

  return (
    <>
      <br />
      {displayedPrices.map((price) => (
        <React.Fragment key={price.uuid}>
          <Price item={item} price={price} />
          <br />
        </React.Fragment>
      ))}
    </>
  );
}

function Price({
  item,
  price,
}: {
  item: ItemType;
  price: ItemType["prices"][number];
}) {
  const endEnhancer = () => {
    if (!price.isAvailable) {
      return (
        <Badge
          color={BADGE_COLOR.negative}
          content={<TranslatedString string={I18N_STRINGS.UNAVAILABLE} />}
        />
      );
    }

    return (
      <>
        {formatCurrency(price.price)}
        <AddPriceToOrderButton item={item} price={price} />
      </>
    );
  };

  return (
    <ListItem sublist endEnhancer={endEnhancer}>
      <ListItemLabel>
        <TranslatedString string={price.name} />
      </ListItemLabel>
    </ListItem>
  );
}

function AddPriceToOrderButton({
  item,
  price,
}: {
  item: ItemType;
  price: ItemType["prices"][number];
}) {
  const { addToOrder, getQuantity } = useOrderContext();
  const [showModal, setShowModal] = React.useState(false);

  const quantity = getQuantity(price.uuid);

  return (
    <>
      <Button
        size={BUTTON_SIZE.compact}
        shape={BUTTON_SHAPE.circle}
        kind={BUTTON_KIND.secondary}
        overrides={{
          BaseButton: {
            style: {
              marginLeft: "0.5em",
            },
          },
        }}
        onClick={() => {
          if (quantity === 0) {
            addToOrder(price.uuid, 1, "");
          } else {
            setShowModal(true);
          }
        }}
      >
        {quantity === 0 ? <MdAdd /> : quantity}
      </Button>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <ModalHeader>
          <TranslatedString string={item.name} />
        </ModalHeader>
        <ModalBody>
          <PriceQuantitySelector price={price} />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => setShowModal(false)}
            kind={BUTTON_KIND.tertiary}
          >
            <TranslatedString string={I18N_STRINGS.OK} />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
