import type { Item } from "../../../contexts/MenuContext";
import { MdAdd, MdRemove, MdDeleteForever } from "react-icons/md";
import { Input, SIZE as INPUT_SIZE } from "baseui/input";
import { useOrderContext } from "../../../contexts/OrderContext";
import {
  Button,
  SIZE as BUTTON_SIZE,
  SHAPE as BUTTON_SHAPE,
  KIND as BUTTON_KIND,
} from "baseui/button";

export default function PriceQuantitySelector({
  price,
  secondary,
}: {
  price: Item["prices"][0];
  secondary?: boolean;
}) {
  const { addToOrder, removeFromOrder, setQuantity, getQuantity } =
    useOrderContext();

  const quantity = getQuantity(price.uuid);

  return (
    <Input
      value={quantity}
      type="number"
      size={secondary ? INPUT_SIZE.compact : INPUT_SIZE.default}
      onChange={(event) => {
        const newQuantity = parseInt(event.currentTarget.value, 10);
        if (isNaN(newQuantity)) {
          setQuantity(price.uuid, 0);
        } else {
          if (newQuantity < 0 || newQuantity > 999) {
            return;
          }
          setQuantity(price.uuid, newQuantity);
        }
      }}
      startEnhancer={() => (
        <Button
          shape={BUTTON_SHAPE.circle}
          size={secondary ? BUTTON_SIZE.compact : BUTTON_SIZE.default}
          kind={secondary ? BUTTON_KIND.secondary : BUTTON_KIND.primary}
          onClick={() => removeFromOrder(price.uuid, 1)}
          disabled={quantity <= 0}
        >
          {quantity <= 1 ? <MdDeleteForever /> : <MdRemove />}
        </Button>
      )}
      endEnhancer={() => (
        <Button
          shape={BUTTON_SHAPE.circle}
          size={secondary ? BUTTON_SIZE.compact : BUTTON_SIZE.default}
          kind={secondary ? BUTTON_KIND.secondary : BUTTON_KIND.primary}
          onClick={() => addToOrder(price.uuid, 1, "")}
          disabled={quantity >= 999}
        >
          <MdAdd />
        </Button>
      )}
      overrides={{
        Root: {
          style: {
            width: "100%",
          },
        },
        Input: {
          style: {
            textAlign: "center",
          },
        },
      }}
    />
  );
}
