import { Modal } from "baseui/modal";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function ModalHashRoute({
  hash,
  children,
  ...rest
}: {
  hash: string;
  children: ({ onClose }: { onClose: () => void }) => React.ReactNode;
  [key: string]: any;
}) {
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(location.hash === hash);
  }, [location.hash, hash]);

  const onClose = () => {
    if (window.history.length === 1) {
      navigate(location.pathname);
      return;
    }

    navigate(-1);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...rest}>
      {children({ onClose })}
    </Modal>
  );
}
