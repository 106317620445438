import { Block } from "baseui/block";
import { Spinner } from "baseui/spinner";

export default function LoadingPage() {
  return (
    <Block
      overrides={{
        Block: {
          style: {
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      }}
    >
      <Spinner />
    </Block>
  );
}
