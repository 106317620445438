import { Notification } from "baseui/notification";

export default function ShadowedNotification({
  children,
  ...props
}: React.ComponentProps<typeof Notification>) {
  const overrides = {
    ...props.overrides,
    Body: {
      ...props.overrides?.Body,
      style: {
        ...props.overrides?.Body?.style,
        boxShadow: "5px 5px 30px 1px rgba(0,0,0,.3)",
      },
    },
  };

  return (
    <Notification {...props} overrides={overrides}>
      {children}
    </Notification>
  );
}
