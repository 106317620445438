import { MandatoryAllStringTranslations } from "../types";

const I18N_STRINGS = {
  // Menu
  UNAVAILABLE: {
    translations: [
      {
        languageCode: "it-it",
        value: "Non disponibile",
      },
      {
        languageCode: "en-gb",
        value: "Unavailable",
      },
      {
        languageCode: "fr-fr",
        value: "Indisponible",
      },
      {
        languageCode: "es-es",
        value: "No disponible",
      },
      {
        languageCode: "de-de",
        value: "Nicht verfügbar",
      },
      {
        languageCode: "zh-cn",
        value: "不可用",
      },
    ],
  },
  OK: {
    translations: [
      {
        languageCode: "it-it",
        value: "Ok",
      },
      {
        languageCode: "en-gb",
        value: "Ok",
      },
      {
        languageCode: "fr-fr",
        value: "Ok",
      },
      {
        languageCode: "es-es",
        value: "Ok",
      },
      {
        languageCode: "de-de",
        value: "Ok",
      },
      {
        languageCode: "zh-cn",
        value: "好的",
      },
    ],
  },
  YOUR_ORDER: {
    translations: [
      {
        languageCode: "it-it",
        value: "Il tuo ordine",
      },
      {
        languageCode: "en-gb",
        value: "Your order",
      },
      {
        languageCode: "fr-fr",
        value: "Votre commande",
      },
      {
        languageCode: "es-es",
        value: "Tu orden",
      },
      {
        languageCode: "de-de",
        value: "Deine Bestellung",
      },
      {
        languageCode: "zh-cn",
        value: "你的订单",
      },
    ],
  },
  EMPTY_ORDER: {
    translations: [
      {
        languageCode: "it-it",
        value: "Il tuo ordine è vuoto",
      },
      {
        languageCode: "en-gb",
        value: "Your order is empty",
      },
      {
        languageCode: "fr-fr",
        value: "Votre commande est vide",
      },
      {
        languageCode: "es-es",
        value: "Tu orden está vacía",
      },
      {
        languageCode: "de-de",
        value: "Deine Bestellung ist leer",
      },
      {
        languageCode: "zh-cn",
        value: "您的订单为空",
      },
    ],
  },
  ORDER_TOTAL: {
    translations: [
      {
        languageCode: "it-it",
        value: "Totale",
      },
      {
        languageCode: "en-gb",
        value: "Total",
      },
      {
        languageCode: "fr-fr",
        value: "Total",
      },
      {
        languageCode: "es-es",
        value: "Total",
      },
      {
        languageCode: "de-de",
        value: "Gesamt",
      },
      {
        languageCode: "zh-cn",
        value: "总计",
      },
    ],
  },
  CLOSE: {
    translations: [
      {
        languageCode: "it-it",
        value: "Chiudi",
      },
      {
        languageCode: "en-gb",
        value: "Close",
      },
      {
        languageCode: "fr-fr",
        value: "Fermer",
      },
      {
        languageCode: "es-es",
        value: "Cerrar",
      },
      {
        languageCode: "de-de",
        value: "Schließen",
      },
      {
        languageCode: "zh-cn",
        value: "关",
      },
    ],
  },
  CLEAR_ORDER: {
    translations: [
      {
        languageCode: "it-it",
        value: "Svuota ordine",
      },
      {
        languageCode: "en-gb",
        value: "Clear order",
      },
      {
        languageCode: "fr-fr",
        value: "Effacer la commande",
      },
      {
        languageCode: "es-es",
        value: "Borrar orden",
      },
      {
        languageCode: "de-de",
        value: "Bestellung löschen",
      },
      {
        languageCode: "zh-cn",
        value: "清除订单",
      },
    ],
  },
  PLACE_ORDER: {
    translations: [
      {
        languageCode: "it-it",
        value: "Effettua ordine",
      },
      {
        languageCode: "en-gb",
        value: "Place order",
      },
      {
        languageCode: "fr-fr",
        value: "Passer la commande",
      },
      {
        languageCode: "es-es",
        value: "Realizar pedido",
      },
      {
        languageCode: "de-de",
        value: "Bestellung aufgeben",
      },
      {
        languageCode: "zh-cn",
        value: "下订单",
      },
    ],
  },

  // Order
  ORDER: {
    translations: [
      {
        languageCode: "it-it",
        value: "Ordine",
      },
      {
        languageCode: "en-gb",
        value: "Order",
      },
      {
        languageCode: "fr-fr",
        value: "Ordre",
      },
      {
        languageCode: "es-es",
        value: "Orden",
      },
      {
        languageCode: "de-de",
        value: "Auftrag",
      },
      {
        languageCode: "zh-cn",
        value: "订购",
      },
    ],
  },
  SHOW_ORDER_DETAILS: {
    translations: [
      {
        languageCode: "it-it",
        value: "Mostra dettagli ordine",
      },
      {
        languageCode: "en-gb",
        value: "Show order details",
      },
      {
        languageCode: "fr-fr",
        value: "Afficher les détails de la commande",
      },
      {
        languageCode: "es-es",
        value: "Mostrar detalles del pedido",
      },
      {
        languageCode: "de-de",
        value: "Bestelldetails anzeigen",
      },
      {
        languageCode: "zh-cn",
        value: "显示订单详细信息",
      },
    ],
  },
  HIDE_ORDER_DETAILS: {
    translations: [
      {
        languageCode: "it-it",
        value: "Nascondi dettagli ordine",
      },
      {
        languageCode: "en-gb",
        value: "Hide order details",
      },
      {
        languageCode: "fr-fr",
        value: "Masquer les détails de la commande",
      },
      {
        languageCode: "es-es",
        value: "Ocultar detalles del pedido",
      },
      {
        languageCode: "de-de",
        value: "Bestelldetails ausblenden",
      },
      {
        languageCode: "zh-cn",
        value: "隐藏订单详细信息",
      },
    ],
  },
  PAYMENT_METHOD_CARD: {
    translations: [
      {
        languageCode: "it-it",
        value: "Carta di credito",
      },
      {
        languageCode: "en-gb",
        value: "Card",
      },
      {
        languageCode: "fr-fr",
        value: "Carte",
      },
      {
        languageCode: "es-es",
        value: "Tarjeta",
      },
      {
        languageCode: "de-de",
        value: "Karte",
      },
      {
        languageCode: "zh-cn",
        value: "卡",
      },
    ],
  },
  FIRST_NAME: {
    translations: [
      {
        languageCode: "it-it",
        value: "Nome",
      },
      {
        languageCode: "en-gb",
        value: "First name",
      },
      {
        languageCode: "fr-fr",
        value: "Prénom",
      },
      {
        languageCode: "es-es",
        value: "Nombre",
      },
      {
        languageCode: "de-de",
        value: "Vorname",
      },
      {
        languageCode: "zh-cn",
        value: "名字",
      },
    ],
  },
  LAST_NAME: {
    translations: [
      {
        languageCode: "it-it",
        value: "Cognome",
      },
      {
        languageCode: "en-gb",
        value: "Last name",
      },
      {
        languageCode: "fr-fr",
        value: "Nom de famille",
      },
      {
        languageCode: "es-es",
        value: "Apellido",
      },
      {
        languageCode: "de-de",
        value: "Nachname",
      },
      {
        languageCode: "zh-cn",
        value: "姓",
      },
    ],
  },
  PHONE_NUMBER: {
    translations: [
      {
        languageCode: "it-it",
        value: "Numero di telefono",
      },
      {
        languageCode: "en-gb",
        value: "Phone number",
      },
      {
        languageCode: "fr-fr",
        value: "Numéro de téléphone",
      },
      {
        languageCode: "es-es",
        value: "Número de teléfono",
      },
      {
        languageCode: "de-de",
        value: "Telefonnummer",
      },
      {
        languageCode: "zh-cn",
        value: "电话号码",
      },
    ],
  },
  EMAIL: {
    translations: [
      {
        languageCode: "it-it",
        value: "Email",
      },
      {
        languageCode: "en-gb",
        value: "Email",
      },
      {
        languageCode: "fr-fr",
        value: "Email",
      },
      {
        languageCode: "es-es",
        value: "Email",
      },
      {
        languageCode: "de-de",
        value: "Email",
      },
      {
        languageCode: "zh-cn",
        value: "电子邮件",
      },
    ],
  },
  ADD_NOTE: {
    translations: [
      {
        languageCode: "it-it",
        value: "Aggiungi nota",
      },
      {
        languageCode: "en-gb",
        value: "Add note",
      },
      {
        languageCode: "fr-fr",
        value: "Ajouter une note",
      },
      {
        languageCode: "es-es",
        value: "Agregar nota",
      },
      {
        languageCode: "de-de",
        value: "Notiz hinzufügen",
      },
      {
        languageCode: "zh-cn",
        value: "添加注释",
      },
    ],
  },
  NOTES: {
    translations: [
      {
        languageCode: "it-it",
        value: "Note",
      },
      {
        languageCode: "en-gb",
        value: "Notes",
      },
      {
        languageCode: "fr-fr",
        value: "Remarques",
      },
      {
        languageCode: "es-es",
        value: "Notas",
      },
      {
        languageCode: "de-de",
        value: "Anmerkungen",
      },
      {
        languageCode: "zh-cn",
        value: "笔记",
      },
    ],
  },
  PAYMENT_METHOD: {
    translations: [
      {
        languageCode: "it-it",
        value: "Metodo di pagamento",
      },
      {
        languageCode: "en-gb",
        value: "Payment method",
      },
      {
        languageCode: "fr-fr",
        value: "Mode de paiement",
      },
      {
        languageCode: "es-es",
        value: "Método de pago",
      },
      {
        languageCode: "de-de",
        value: "Zahlungsmethode",
      },
      {
        languageCode: "zh-cn",
        value: "付款方法",
      },
    ],
  },
  CONFIRM_ORDER: {
    translations: [
      {
        languageCode: "it-it",
        value: "Conferma ordine",
      },
      {
        languageCode: "en-gb",
        value: "Confirm order",
      },
      {
        languageCode: "fr-fr",
        value: "Confirmer la commande",
      },
      {
        languageCode: "es-es",
        value: "Confirmar orden",
      },
      {
        languageCode: "de-de",
        value: "Bestellung bestätigen",
      },
      {
        languageCode: "zh-cn",
        value: "确认订单",
      },
    ],
  },
  ORDER_NOT_CONFIRMED_FILL_FORM: {
    translations: [
      {
        languageCode: "it-it",
        value:
          "L'ordine non è ancora confermato. Si prega di inserire i propri dati qui sotto per confermare l'ordine.",
      },
      {
        languageCode: "en-gb",
        value:
          "The order is not confirmed yet. Please fill in your details below to confirm the order.",
      },
      {
        languageCode: "fr-fr",
        value:
          "La commande n'est pas encore confirmée. Veuillez remplir vos coordonnées ci-dessous pour confirmer la commande.",
      },
      {
        languageCode: "es-es",
        value:
          "El pedido aún no está confirmado. Rellene sus datos a continuación para confirmar el pedido.",
      },
      {
        languageCode: "de-de",
        value:
          "Die Bestellung ist noch nicht bestätigt. Bitte geben Sie Ihre Daten unten ein, um die Bestellung zu bestätigen.",
      },
      {
        languageCode: "zh-cn",
        value: "订单尚未确认。请在下面填写您的详细信息以确认订单。",
      },
    ],
  },
  ORDER_NOT_CONFIRMED_PAY: {
    translations: [
      {
        languageCode: "it-it",
        value:
          "L'ordine non è ancora confermato. Si prega di effettuare il pagamento per confermare l'ordine.",
      },
      {
        languageCode: "en-gb",
        value:
          "The order is not confirmed yet. Please make the payment to confirm the order.",
      },
      {
        languageCode: "fr-fr",
        value:
          "La commande n'est pas encore confirmée. Veuillez effectuer le paiement pour confirmer la commande.",
      },
      {
        languageCode: "es-es",
        value:
          "El pedido aún no está confirmado. Por favor, realice el pago para confirmar el pedido.",
      },
      {
        languageCode: "de-de",
        value:
          "Die Bestellung ist noch nicht bestätigt. Bitte bezahlen Sie, um die Bestellung zu bestätigen.",
      },
      {
        languageCode: "zh-cn",
        value: "订单尚未确认。请付款以确认订单。",
      },
    ],
  },
  ORDER_CONFIRMED: {
    translations: [
      {
        languageCode: "it-it",
        value: "L'ordine è confermato.",
      },
      {
        languageCode: "en-gb",
        value: "The order is confirmed.",
      },
      {
        languageCode: "fr-fr",
        value: "La commande est confirmée.",
      },
      {
        languageCode: "es-es",
        value: "El pedido está confirmado.",
      },
      {
        languageCode: "de-de",
        value: "Die Bestellung ist bestätigt.",
      },
      {
        languageCode: "zh-cn",
        value: "订单已确认。",
      },
    ],
  },
  PAY_NOW: {
    translations: [
      {
        languageCode: "it-it",
        value: "Paga ora",
      },
      {
        languageCode: "en-gb",
        value: "Pay now",
      },
      {
        languageCode: "fr-fr",
        value: "Payer maintenant",
      },
      {
        languageCode: "es-es",
        value: "Pagar ahora",
      },
      {
        languageCode: "de-de",
        value: "Jetzt bezahlen",
      },
      {
        languageCode: "zh-cn",
        value: "现在付款",
      },
    ],
  },
} satisfies Record<string, MandatoryAllStringTranslations>;

export default I18N_STRINGS;
