import React from "react";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { LanguageCode } from "../i18n";
import { ShadowedCard } from "../card/ShadowedCard";
import CardIcon from "./images/card-icon";
import SatispayIcon from "./images/satispay-icon";

const i18nStrings = {
  "en-gb": {
    card: "Card",
    satispay: "Satispay",
  },
  "it-it": {
    card: "Carta",
    satispay: "Satispay",
  },
};

export function PaymentMethodSelector({
  paymentMethods,
  paymentMethodUuid,
  setPaymentMethodUuid,
  language,
}: {
  paymentMethods: { uuid: string; provider: "stripe" | "satispay" | null }[];
  paymentMethodUuid: string;
  setPaymentMethodUuid: (paymentMethodUuid: string) => void;
  language: string;
}) {
  const strings =
    language in i18nStrings
      ? i18nStrings[language as LanguageCode]
      : i18nStrings["en-gb"];
  return (
    <FlexGrid flexGridColumnCount={[1, 1, 2, 3]} flexGridColumnGap="1em">
      {paymentMethods.map((pm) => (
        <FlexGridItem key={pm.uuid}>
          <ShadowedCard
            overrides={{
              Root: {
                style: {
                  cursor: "pointer",
                  marginBottom: "1em",
                  borderWidth: "3.5px",
                  borderColor: paymentMethodUuid === pm.uuid ? "#000" : "#fff",
                  transition: "border-color 0.2s ease-in-out",
                },
              },
            }}
            onClick={() => setPaymentMethodUuid(pm.uuid)}
          >
            {pm.provider === "stripe" ? (
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <CardIcon style={{ width: "1.5em", height: "1.5em" }} />
                <span>{strings.card}</span>
              </div>
            ) : pm.provider === "satispay" ? (
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <SatispayIcon style={{ width: "1.5em", height: "1.5em" }} />
                <span>{strings.satispay}</span>
              </div>
            ) : null}
          </ShadowedCard>
        </FlexGridItem>
      ))}
    </FlexGrid>
  );
}
